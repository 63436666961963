import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async () => {
  // Here you can use wixCodeApi (Velo), API reference is here:
  // https://bo.wix.com/wix-docs/fe-guild/viewer-platform/ooi-reference/wixcode-apis
  // And Wix Selector ($w) to manipulate with elements on the page, API reference is here:
  // https://www.wix.com/velo/reference/$w
  // controllerConfig has more useful data, for the details look inside types and here:
  // https://bo.wix.com/wix-docs/fe-guild/viewer-platform/ooi-articles/lifecycle
  // const { wixCodeApi } = controllerConfig;
  // const $w = controllerConfig.$w as any;

  return {
    pageReady: () => {
      // do nothing
    },
  };
};

export default createController;

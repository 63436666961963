import { CreateControllerFn } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({
  appData,
  controllerConfig,
}) => {
  return {
    pageReady: (_, wixApi) => {
      if (!wixApi || !wixApi.site.currentPage) {
        return;
      }
      const pageId = wixApi.site.currentPage.id;

      const { metaSiteId } = appData as { metaSiteId: string };
      const wixWindow = controllerConfig.wixCodeApi.window;
      const wixLocation = controllerConfig.wixCodeApi.location;

      if (wixLocation.query.inBizMgr) {
        (wixWindow as any).postMessage({
          code: 'admin-pages-ready-update',
          pageId,
        });
      } else {
        wixLocation.to!(buildBusinessManagerUrl(metaSiteId, pageId));
      }
    },
  };
};

export default createController;

const buildBusinessManagerUrl = (metasiteId, pageId) =>
  `https://www.wix.com/dashboard/${metasiteId}/admin-pages/${pageId}?referralInfo=sidebar`;
